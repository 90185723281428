import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Select, Space, message } from 'antd';
import "./App.css";
const App: React.FC = () => {
	const [form] = Form.useForm();
	const portRef = useRef<any>(null);
	const renderRef = useRef<any>(null);
	// 重量
	const [weight, setWeight] = useState(0);
	// 小数位数
	const [decimalNumber, setDecimalNumber] = useState(1);
	// 单位
	const [unit, setUnit] = useState('');
	const timer = useRef<any>(null);

	/**
	 * 连接设备
	 * @param values 
	 */
	const onConnect = async (values: any) => {
		const reqData: any = {};
		Object.keys(values).forEach(item => {
			if (values[item]) {
				reqData[item] = values[item];
			}
		})
		console.log("连接参数：", reqData, navigator)
		if ((navigator as any)?.serial) {
			const port = await (navigator as any).serial.requestPort();
			portRef.current = port;
			await port.open(values);

			// 创建一个读取器
			renderRef.current = port.readable.getReader();
			readData();
		}
	}

	const readData = async () => {
		// 读取一块数据
		const { value, done } = await renderRef.current.read();
		// 当串口关闭或者出现错误时，done会为true
		if (done) {
			renderRef.current.releaseLock();
		}
		// 在这里处理接收到的数据
		handleDeal(value);
		timer.current = setTimeout(readData, 50);
	}

	const handleDeal = (value: any) => {
		const hx = Array.prototype.map.call(value, x => ('00' + x.toString(16)).slice(-2)).join('');
		if (hx.length === 30 && hx.substring(0, 2).toUpperCase() === 'AA' && hx.substring(28, 30).toUpperCase() === '2F') {
			console.log("有效数据：", hx);
			timer.current = Date.now();
			const hxWeight = hx.substring(10, 18);
			const dm = hx.substring(8, 10);
			const unit = hx.substring(6, 8);
			setWeight(parseInt(hxWeight, 16));
			setDecimalNumber(parseInt(dm, 16));
			handleUnit(unit);
		}
	}

	/**
	 * 16 进制转2 进制
	 * @param hexString 
	 * @returns 
	 */
	const hexToBinary = (hexString: any) => {
		let decimal = parseInt(hexString, 16);
		return decimal.toString(2);
	}

	const handleUnit = (hexString: any) => {
		const binaryStr = hexToBinary(hexString);
		const unitStr = binaryStr.substring(1);
		const unit = parseInt(unitStr, 2);
		if (unit === 1) {
			setUnit('公斤')
		} else if (unit === 2) {
			setUnit('克');
		} else if (unit === 3) {
			setUnit('斤');
		} else {
			setUnit('未知');
		}
	}

	const onCloseConnect = async () => {
		if (portRef.current && renderRef.current) {
			try {
				console.log("renderRef", portRef, renderRef);
				await renderRef.current.cancel();
				portRef.current.close();
				clearTimeout(timer.current);
				message.info("设备已断开");
			} catch (error) {
				console.log("关闭出错", error)
			}

		}
	}

	useEffect(() => {
		console.log("组件加载");
		return () => {
			console.log("组件卸载");
			onCloseConnect();
		}
	}, []);

	return <div className='container'>
		<Form
			name="basic"
			initialValues={{
				baudRate: 115200,
				dataBits: 8,
				stopBits: 1,
				parity: 'none',
				flowControl: 'none'
			}}
			form={form}
			className='form-card'
		>
			<Form.Item
				label="波特率"
				name="baudRate"
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="数据位"
				name="dataBits"
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="停止位"
				name="stopBits"
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="奇偶校验"
				name="parity"
			>
				<Select allowClear>
					<Select.Option value="none">none</Select.Option>
					<Select.Option value="even">even</Select.Option>
					<Select.Option value="odd">odd</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item
				label="流控制"
				name="flowControl"
			>
				<Select allowClear>
					<Select.Option value="none">none</Select.Option>
					<Select.Option value="hardware">hardware</Select.Option>
					<Select.Option value="software">software</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item >
				<Space align='end' style={{ width: '100%' }}>
					<Button danger onClick={onCloseConnect}>
						断开连接
					</Button>
					<Button type="primary" onClick={() => {
						const data = form.getFieldsValue();
						onConnect(data);
					}}>
						连接设备
					</Button>
				</Space>
			</Form.Item>
		</Form>

		<div style={{ width: '100%' }}>
			<div>
				<span>重量: </span>
				<span style={{ margin: '0 10px' }}>{weight / Math.pow(10, decimalNumber)}</span>
				<span>{unit}</span>
			</div>
		</div>
	</div>
}

export default App;
